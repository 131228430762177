.tag-cloud span {
    color: azure;
    transition: all 0.2s ease-in-out;
    font-size: 1.2rem;
    text-transform: uppercase;
  }
  
  .tag-cloud span:hover {
    transform: scale(1.05);
  }

/* .tag-cloud span {
    display: inline-block;
    padding: 0.5rem 1rem;
    margin: 0.5rem;
    background-color: rgba(128, 204, 179, 1);
    border-radius: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
} */

.tag-cloud {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Try to distribute the tags evenly */
}
